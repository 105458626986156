import { orderStatusesLogistic } from '@/helpers/select-items'

export default [
  {
    path: '/logistics/orders',
    name: 'pageLogisticsOrders',
    meta: {
      title: 'page.pageLogisticsOrders',
      roles: orderStatusesLogistic.map(s => `LOGISTIC_ORDER_VIEW_${s.id.toUpperCase()}`)
    },
    component: () => import('@/pages/logistics/orders'),
  },
  {
    path: '/logistics/orders/create',
    name: 'pageLogisticsOrdersCreate',
    meta: { title: 'page.pageOrderCreate', roles: ['LOGISTIC_ORDER_CREATE'] },
    component: () => import('@/pages/logistics/orders/create'),
  },
  {
    path: '/logistics/orders/:id/edit',
    name: 'pageLogisticsOrdersEdit',
    meta: {
      title: 'page.pageOrderEdit',
      roles: orderStatusesLogistic.map(s => `LOGISTIC_ORDER_VIEW_${s.id.toUpperCase()}`)
    },
    component: () => import('@/pages/logistics/orders/id/edit'),
  },
]
