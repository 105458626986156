export const FINANCIAL_REPORT = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  OPERATOR_GROUPS: 'operator_groups',
  OPERATOR: 'operator',
  DELIVERY_SERVICE: 'delivery_service',
  DELIVERY_TYPE: 'delivery_type',
  COUNTRY: 'country',
  REGION: 'region',
  CITY: 'city',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
  AGE: 'age',
  GENDER: 'gender',
  WEB: 'web',
  LANDING: 'landing',
  ORGANIZATION: 'organization',
  PRODUCT_TYPE: 'product_type',
  WAREHOUSE: 'warehouse',
}
