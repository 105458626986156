import SuperRepository from '../superRepository'

export default new (class UserRepository extends SuperRepository {
  baseUri() {
    return 'user'
  }
  list = async (params, config = {}) => {
    return this.httpClient().get(`${this.baseUri()}`, params, config)
  }
  bulkUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_update`, data, params)
  bulkActiveStatusUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/bulk_activate`, data, params)
  addFavoriteOrder = async (id) =>
    this.httpClient().post(`${this.baseUri()}/${id}/add_favorite_order`)
  removeFavoriteOrder = async (id) =>
    this.httpClient().post(`${this.baseUri()}/${id}/remove_favorite_order`)
  bulkAddFavoriteOrder = async (data) =>
    this.httpClient().post(`${this.baseUri()}/bulk_add_favorite_order`, data)
  bulkRemoveFavoriteOrder = async (data) =>
    this.httpClient().post(`${this.baseUri()}/bulk_remove_favorite_order`, data)
  penalty = async params => this.httpClient().get(`${this.baseUri()}/payment_and_hold`, params)
  ordersQueue = async params => this.httpClient().get(`${this.baseUri()}/get_order_queue_count`, params)
  ordersQueueManager = async params => this.httpClient().get(`${this.baseUri()}/get_order_queue_count_manager`, params)
  getOrderForOperator = async params => this.httpClient().get(`${this.baseUri()}/get_order`, params)
  bulkPenaltyUpdate = async (data, params) =>
    this.httpClient().put(`${this.baseUri()}/payment_and_penalty_bulk_create`, data, params)
})()
