import i18n from '@/plugins/vue-i18n'

export const SYSTEM_MACROS = {
  MAIN: 'main',
  SUBSTITUTE: 'substitute',
  ALTERNATE: 'manually',
  ADDITIONAL_CROSS: 'additional_cross',
  AWARE_CROSS: 'aware_cross',
  ANOTHER: 'another',
}

export const SYSTEM_MACROS_TRANSACTION = {
  [SYSTEM_MACROS.MAIN]: i18n.tc('salesScript.macrosStatus.main'),
  [SYSTEM_MACROS.SUBSTITUTE]: i18n.tc('salesScript.macrosStatus.substitute'),
  [SYSTEM_MACROS.ALTERNATE]: i18n.tc('salesScript.macrosStatus.manually'),
  [SYSTEM_MACROS.ADDITIONAL_CROSS]: i18n.tc('salesScript.macrosStatus.additional_cross'),
  [SYSTEM_MACROS.AWARE_CROSS]: i18n.tc('salesScript.macrosStatus.aware_cross'),
  [SYSTEM_MACROS.ANOTHER]: i18n.tc('salesScript.macrosStatus.another'),
}
