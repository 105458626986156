export const LEGAL_STATS_TYPE = {
  WAREHOUSE: 'warehouse',
  ORGANIZATION: 'organization',
  DELIVERY_SERVICE: 'delivery_service',
  DELIVERY_TYPE: 'delivery_type',
  OFFER: 'offer',
  PRODUCT: 'offer_main_product',
  COUNTRY: 'country',
  REGION: 'region',
}
