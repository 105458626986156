import i18n from '@/plugins/vue-i18n'
import { ORDER_STATUS } from '@/constans/orderStatuses'

export const orderStatTranslation = {
  [ORDER_STATUS.ACCEPTED]: i18n.tc('orderStatuses.accepted'),
  [ORDER_STATUS.CALLBACK]: i18n.tc('orderStatuses.callback'),
  [ORDER_STATUS.APPROVED]: i18n.tc('orderStatuses.approved'),
  [ORDER_STATUS.CANCELED]: i18n.tc('orderStatuses.canceled'),
  [ORDER_STATUS.SHIPPED]: i18n.tc('orderStatuses.shipped'),
  [ORDER_STATUS.DELIVERED]: i18n.tc('orderStatuses.delivered'),
  [ORDER_STATUS.NO_RANSOM]: i18n.tc('orderStatuses.no_ransom'),
  [ORDER_STATUS.PROCESSING]: i18n.tc('orderStatuses.processing'),
  [ORDER_STATUS.INCORRECT]: i18n.tc('orderStatuses.incorrect'),
  [ORDER_STATUS.SUSPICIOUS]: i18n.tc('orderStatuses.suspicious'),
  [ORDER_STATUS.AWAITING_RANSOM]: i18n.tc('orderStatuses.awaiting_ransom'),
  [ORDER_STATUS.TRASH]: i18n.tc('orderStatuses.trash'),
  [ORDER_STATUS.PAID]: i18n.tc('orderStatuses.paid'),
  [ORDER_STATUS.PARTIALLY_PAID]: i18n.tc('orderStatuses.partially_paid'),
  [ORDER_STATUS.PACKAGING]: i18n.tc('orderStatuses.packaging'),
  [ORDER_STATUS.LOST_PACKAGE]: i18n.tc('orderStatuses.lost_package'),
}
