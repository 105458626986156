import { joinBySymbol } from '@/helpers/filters'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  custom_filter: {
    attribute: [],
    callback_count: [],
    call_count: [],
    history_date_from: null,
    history_date_to: null,
  },
  filter: {
    callbackReason: null,
    prepayment: null,
    country: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    region: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    city: {
      id: {
        value: '',
        operator: 'in',
      },
      district: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    operator: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    mainOperator: {
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      }
    },
    offer: {
      id: {
        value: '',
        operator: 'in',
      },
      groups: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      niche: {
        id: {
          value: '',
          operator: 'in',
        },
      },
      salesType: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    tags: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    webId: {
      value: '',
      operator: 'in',
    },
    landing: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    deliveryService: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    warehouse: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    statusReason: {
      id: {
        value: '',
        operator: 'in',
      },
    },
    products: {
      product: {
        id: {
          value: '',
          operator: 'in',
        },
      },
    },
    createdAt: {
      value: '',
      operator: 'bwn',
    },
    orderSubstatus: {
      id: '',
      operator: 'in',
    },
    id: {
      value: '',
      operator: 'in',
    },
    cpaId: {
      value: '',
      operator: 'in',
    },
    partnerId: {
      value: '',
      operator: 'in',
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
    selectedColumns: [],
  },
}

export function prepareOrderReportFilters(filter) {
  const filters = {}
  filters.countries = [...joinBySymbol(filter.countries).split('|')]
  filters.regions = [...joinBySymbol(filter.regions).split('|')]
  filters.districts = [...joinBySymbol(filter.districts).split('|')]
  filters.cities = [...joinBySymbol(filter.cities).split('|')]
  return filters
}
