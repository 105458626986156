export default [
    {
        path: '/settings/product-move',
        name: 'pageProductMoveList',
        meta: { title: 'productMove.listTitle' },
        component: () => import('@/pages/settings/product-move'),
    },
    {
        path: '/settings/product-move/create',
        name: 'pageProductMoveCreate',
        meta: { title: 'productMove.createFormTitle' },
        component: () => import('@/pages/settings/product-move/create'),
    },
    {
        path: '/settings/product-move/:id/edit',
        name: 'pageProductMoveEdit',
        meta: { title: 'productMove.editFormTitle' },
        component: () => import('@/pages/settings/product-move/id/edit'),
    },
]
