export default [
    {
        path: '/settings/user-weekend',
        name: 'pageUserWeekendList',
        meta: { title: 'userWeekend.listTitle', roles: ['SETTING_USER_WEEKEND_VIEW'] },
        component: () => import('@/pages/settings/user-weekend'),
    },
    {
        path: '/settings/user-weekend/create',
        name: 'pageUserWeekendCreate',
        meta: { title: 'userWeekend.createFormTitle', roles: ['SETTING_USER_WEEKEND_OPERATE'] },
        component: () => import('@/pages/settings/user-weekend/create'),
    },
    {
        path: '/settings/user-weekend/:id/edit',
        name: 'pageUserWeekendEdit',
        meta: { title: 'userWeekend.editFormTitle', roles: ['SETTING_USER_WEEKEND_VIEW'] },
        component: () => import('@/pages/settings/user-weekend/id/edit'),
    },
]