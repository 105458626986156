export const REJECTED_STATISTICS = {
  STATUS: 'status_group',
  STATUS_REASON: 'reject_reason',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  USER_GROUP: 'operator_groups',
  COUNTRY: 'country',
  WEB: 'web',
  OPERATOR: 'operator',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
}
