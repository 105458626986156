import SuperRepository from '../superRepository'

export default new (class ProductMoveRepository extends SuperRepository {
  baseUri() {
    return 'product_move'
  }

  productMoveExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/export`, data, params)
})()
