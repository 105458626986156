import moment from 'moment'
import i18n from '@/plugins/vue-i18n'
import { orderStatTranslation } from '@/constans/orderStatTranslation'
import { ORDER_STATUS } from '@/constans/orderStatuses'
import { OPERATOR_STATUS } from '@/constans/operatorStatuses'
import { EVALUATIONS_OPTIONS } from '@/constans/evaluationOptions'
import { KLN_COLOR, KLN_STATUS, KLN_TRANSACTION } from '@/constans/klnStatuses'
import { LEGAL_STATS_TYPE } from '@/constans/legalStatsTypes'
import { CALL_CENTRE_STATISTICS } from '@/constans/callCentreStatistics'
import { CALL_STATUS, callStatTranslation } from '@/constans/callStatuses'
import { SYSTEM_MACROS, SYSTEM_MACROS_TRANSACTION } from '@/constans/scriptMacroses'
import { REJECTED_STATISTICS } from '@/constans/rejectedStatistics'
import { RECONFIRMATION_STATISTICS } from '@/constans/reconfirmationStatistic'
import { CALL_STATISTICS } from '@/constans/callStatistics'
import { FINANCIAL_REPORT } from '@/constans/financialReport'
import { AVERAGE_CHECK } from '@/constans/averageCheck'
import { HANGING_RANSOM } from '@/constans/hangingRansom'
import { APPROVE_SPEED } from '@/constans/approveSpeed'
import { DOSTAVIM } from '@/constans/dostavim'
import { PENALTY } from '@/constans/penalty'
import { DELIVERY_SERVICE_ANALYTIC } from '@/constans/deliveryServiceAnalytic'

// start Oleh
export const yesNoSelectAll = [
  { id: null, name: i18n.tc('base.all') },
  { id: 1, name: i18n.tc('base.yes') },
  { id: 0, name: i18n.tc('base.no') },
]
export const yesNoSelectAllOrderSync = [
  { id: null, name: i18n.tc('base.all') },
  { id: 0, name: i18n.tc('base.yes') },
  { id: 1, name: i18n.tc('base.no') },
]
export const yesNoSelect = [
  { id: true, name: i18n.tc('base.yes') },
  { id: false, name: i18n.tc('base.no') },
]
export const activeStatuses = [
  { id: true, name: i18n.tc('base.listStatusActive') },
  { id: false, name: i18n.tc('base.listStatusInactive') },
]

export const callbackStatuses = [
  { id: 'busy', name: i18n.tc('callbackReasons.busy') },
  { id: 'no_answer', name: i18n.tc('callbackReasons.no_answer') },
  { id: 'disconnented', name: i18n.tc('callbackReasons.disconnented') },
  { id: 'no_free_operators', name: i18n.tc('callbackReasons.no_free_operators') },
  { id: 'technical_error', name: i18n.tc('callbackReasons.technical_error') },
  { id: 'operator_did_not_answer', name: i18n.tc('callbackReasons.operator_did_not_answer') },
  { id: 'ask_callback', name: i18n.tc('callbackReasons.ask_callback') },
  { id: 'send_later', name: i18n.tc('callbackReasons.send_later') },
  { id: 'specify_info', name: i18n.tc('callbackReasons.specify_info') },
  { id: 'number_not_available', name: i18n.tc('callbackReasons.number_not_available') },
]
// end Oleh
export const booleanSelectItems = [
  {
    uuid: 1,
    name: i18n.tc('base.yes'),
  },
  {
    uuid: 0,
    name: i18n.tc('base.no'),
  },
  {
    uuid: '',
    name: i18n.tc('filters.all'),
  },
]

export const substatusesItems = [
  {
    uuid: 'shipped',
    name: i18n.tc('orderStatuses.shipped'),
  },
  {
    uuid: 'delivered',
    name: i18n.tc('orderStatuses.delivered'),
  },
  {
    uuid: 'no_ransom',
    name: i18n.tc('orderStatuses.no_ransom'),
  },
  {
    uuid: 'callback',
    name: i18n.tc('orderStatuses.callback'),
  },
  {
    uuid: 'packaging',
    name: i18n.tc('orderStatuses.packaging'),
  },
]
export const operatorDashboardPeriodItems = [
  { id: 1, name: 'day', label: i18n.tc('base.today') },
  { id: 2, name: 'month', label: i18n.tc('base.currentMonth') },
]
export const operatorDashboardGradeItems = [
  { id: 'hot', label: i18n.tc('operatorGrade.saleType.hot') },
  { id: 'base', label: i18n.tc('operatorGrade.saleType.base') },
  { id: 'external', label: i18n.tc('operatorGrade.saleType.external') },
]

export const deadlineFormatItems = [
  { uuid: 'exact', name: i18n.tc('ticketTypes.select.exact') },
  { uuid: 'timer', name: i18n.tc('ticketTypes.select.timer') },
]

export const twoFactorAuthSelect = [
  {
    uuid: true,
    name: i18n.tc('twoFactorAuthSelect.enabled'),
  },
  {
    uuid: false,
    name: i18n.tc('twoFactorAuthSelect.disabled'),
  },
]

export const autoPerformerSignItems = [
  {
    uuid: 'first_approve',
    name: i18n.tc('ticketTypes.autoPerformerSign.first_approve'),
  },
]

export const autoDeadlineSignItems = [
  {
    uuid: 'status_time',
    name: i18n.tc('ticketTypes.select.status_time'),
  },
  {
    uuid: 'create_time',
    name: i18n.tc('ticketTypes.select.create_time'),
  },
]

export const ticketTypesStatusItems = [
  {
    uuid: 'first_approve',
    name: i18n.tc('ticketTypes.select.first_approve'),
  },
  {
    uuid: 'last_approve',
    name: i18n.tc('ticketTypes.select.last_approve'),
  },
  {
    uuid: 'callback',
    name: i18n.tc('orderStatuses.callback'),
  },
  {
    uuid: 'shipped',
    name: i18n.tc('orderStatuses.shipped'),
  },
  {
    uuid: 'awaiting_ransom',
    name: i18n.tc('orderStatuses.awaiting_ransom'),
  },
  {
    uuid: 'delivered',
    name: i18n.tc('orderStatuses.delivered'),
  },
  {
    uuid: 'no_ransom',
    name: i18n.tc('orderStatuses.no_ransom'),
  },
  {
    uuid: 'trash',
    name: i18n.tc('orderStatuses.trash'),
  },
  {
    uuid: 'canceled',
    name: i18n.tc('orderStatuses.canceled'),
  },
]

export const orderStatusItems = [
  {
    uuid: 'accepted',
    name: i18n.tc('base.accepted'),
  },
]

export const unpaidOrdersStatusItems = [
  {
    id: 'delivered',
    name: i18n.tc('orderStatuses.delivered'),
  },
  {
    id: 'partially_paid',
    name: i18n.tc('orderStatuses.partially_paid'),
  },
  {
    id: 'delivered|partially_paid',
    name: i18n.tc('orderStatuses.all'),
  },
]

export const ticketAttributesItems = [
  {
    id: 'no_user',
    name: i18n.tc('tickets.attributes.no_user'),
  },
  {
    id: 'no_group',
    name: i18n.tc('tickets.attributes.no_group'),
  },
  {
    id: 'no_deadline',
    name: i18n.tc('tickets.attributes.no_deadline'),
  },
  {
    id: 'overdue',
    name: i18n.tc('tickets.attributes.overdue'),
  },
]

export const myTicketAttributesItems = [
  {
    id: 'no_deadline',
    name: i18n.tc('tickets.attributes.no_deadline'),
  },
  {
    id: 'overdue',
    name: i18n.tc('tickets.attributes.overdue'),
  },
]

export const visibilityItems = [
  {
    id: 'all',
    name: i18n.tc('base.all'),
  },
  {
    id: 'onlyChosen',
    name: i18n.tc('base.onlyChosen'),
  },
]

export const itemsSystemReason = [
  {
    uuid: 'in_blacklist',
    name: i18n.tc('rejectReason.in_blacklist'),
  },
  {
    uuid: 'invalid_phone',
    name: i18n.tc('rejectReason.invalid_phone'),
  },
  {
    uuid: 'stop_word',
    name: i18n.tc('rejectReason.stop_word'),
  },
  {
    uuid: 'duplicate',
    name: i18n.tc('rejectReason.duplicate'),
  },
]

export const itemsReason = [
  {
    id: 1,
    uuid: 'incorrect',
    name: i18n.tc('rejectReason.incorrect'),
  },
  {
    id: 2,
    uuid: 'canceled',
    name: i18n.tc('rejectReason.canceled'),
  },
  {
    id: 3,
    uuid: 'trash',
    name: i18n.tc('rejectReason.trash'),
  },
  {
    id: 4,
    uuid: 'callback',
    name: i18n.tc('rejectReason.callback'),
  },
]
export const itemsTerminal = [
  { id: 1, name: i18n.tc('city.filter.terminalExist') },
  { id: 0, name: i18n.tc('city.filter.noTerminal') },
  { id: null, name: i18n.tc('city.filter.all') },
]
export const itemsFiltersActive = [
  { id: 1, name: i18n.tc('base.filtersActive') },
  { id: 0, name: i18n.tc('base.filtersDeactivated') },
  { id: null, name: i18n.tc('base.filtersAll') },
]

export const itemsActivity = [
  { id: 1, name: i18n.tc('deliveryPoint.activePoint') },
  { id: 0, name: i18n.tc('deliveryPoint.deactivatedPoint') },
]

export const itemsSalaryTypes = [
  { id: 0, name: i18n.tc('user.teamLeaderCC'), latinName: 'team_leader_cc' },
  { id: 1, name: i18n.tc('user.localLeaderCC'), latinName: 'local_leader_cc' },
  { id: 2, name: i18n.tc('user.operatorCC'), latinName: 'operator_cc' },
]

export const itemsMailOrders = [
  { id: 1, name: i18n.tc('productGift.productGiftInset') },
  { id: 0, name: i18n.tc('productGift.productGiftNotInset') },
  { id: null, name: i18n.tc('productGift.productGiftAll') },
]

export const itemsFiltersStatus = [
  { id: ORDER_STATUS.ACCEPTED, name: orderStatTranslation[ORDER_STATUS.ACCEPTED] },
  { id: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { id: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { id: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
  { id: ORDER_STATUS.AWAITING_RANSOM, name: orderStatTranslation[ORDER_STATUS.AWAITING_RANSOM] },
  { id: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
]

export const orderStatusesCallCentre = [
  { id: ORDER_STATUS.ACCEPTED, name: orderStatTranslation[ORDER_STATUS.ACCEPTED] },
  { id: ORDER_STATUS.CALLBACK, name: orderStatTranslation[ORDER_STATUS.CALLBACK] },
  { id: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { id: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
  { id: ORDER_STATUS.SHIPPED, name: orderStatTranslation[ORDER_STATUS.SHIPPED] },
  { id: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { id: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
  { id: ORDER_STATUS.PACKAGING, name: orderStatTranslation[ORDER_STATUS.PACKAGING] },
  { id: ORDER_STATUS.TRASH, name: orderStatTranslation[ORDER_STATUS.TRASH] },
  { id: ORDER_STATUS.LOST_PACKAGE, name: orderStatTranslation[ORDER_STATUS.LOST_PACKAGE] }, // 11 items
]
export const orderStatusesLogistic = [
  { id: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { id: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
  { id: ORDER_STATUS.PACKAGING, name: orderStatTranslation[ORDER_STATUS.PACKAGING] },
  { id: ORDER_STATUS.SHIPPED, name: orderStatTranslation[ORDER_STATUS.SHIPPED] },
  { id: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { id: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
  { id: ORDER_STATUS.LOST_PACKAGE, name: orderStatTranslation[ORDER_STATUS.LOST_PACKAGE] }, // 8 items
]
export const itemsRelatedOfferStatuses = [
  { id: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
  { id: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { id: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
]
export const orderStatusesForActions = [ // actions modal on orders lists
  { id: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { id: ORDER_STATUS.PACKAGING, name: orderStatTranslation[ORDER_STATUS.PACKAGING] },
  { id: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
  { id: ORDER_STATUS.CALLBACK, name: orderStatTranslation[ORDER_STATUS.CALLBACK] },
  { id: ORDER_STATUS.INCORRECT, name: orderStatTranslation[ORDER_STATUS.INCORRECT] },
  { id: ORDER_STATUS.TRASH, name: orderStatTranslation[ORDER_STATUS.TRASH] },
  { id: ORDER_STATUS.SHIPPED, name: orderStatTranslation[ORDER_STATUS.SHIPPED] },
  { id: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { id: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
  { id: ORDER_STATUS.LOST_PACKAGE, name: orderStatTranslation[ORDER_STATUS.LOST_PACKAGE] },
]

export const callStatuses = [
  { id: CALL_STATUS.DIALING, name: callStatTranslation[CALL_STATUS.DIALING] },
  { id: CALL_STATUS.CALL, name: callStatTranslation[CALL_STATUS.CALL] },
  { id: CALL_STATUS.SUCCESS, name: callStatTranslation[CALL_STATUS.SUCCESS] },
  { id: CALL_STATUS.FAILED_ATTEMPT, name: callStatTranslation[CALL_STATUS.FAILED_ATTEMPT] },
  { id: CALL_STATUS.FAILED, name: callStatTranslation[CALL_STATUS.FAILED] },
]

export const dateFilterTypes = [
  { id: 'createdAt', name: i18n.tc('order.dateFilterType.createdAt') },
  { id: 'approvedStatusDate', name: i18n.tc('order.dateFilterType.approvedAt') },
  { id: 'shippedStatusDate', name: i18n.tc('order.dateFilterType.shippedAt') },
  { id: 'deliveryStatusDate', name: i18n.tc('order.dateFilterType.paidAt') },
]

export const timeHoursItems = new Array(24).fill().map((_, index) => {
  const val = moment({ hour: index }).format('HH:mm')
  return {
    id: val,
    name: val,
  }
})

export const weekdaysShort = moment.weekdaysShort(true).map((val, idx) => {
  return { id: ++idx, name: val }
})

export const deliveryTypeItems = [
  {
    id: 'fulfillment',
    name: i18n.tc('deliveryType.fulfillment'),
  },
]

export const userScheduleStatuses = [
  { uuid: 'work_day', name: i18n.tc('operatorDashboard.scheduleSub.work_day') },
  { uuid: 'day_off', name: i18n.tc('operatorDashboard.scheduleSub.day_off') },
  { uuid: 'vacation', name: i18n.tc('operatorDashboard.scheduleSub.vacation') },
  { uuid: 'sick_leave', name: i18n.tc('operatorDashboard.scheduleSub.sick_leave') },
  { uuid: 'session', name: i18n.tc('operatorDashboard.scheduleSub.session') },
]

export const timesItems = [
  { uuid: 'day', name: i18n.tc('base.day') },
  { uuid: 'hour', name: i18n.tc('base.hour') },
  { uuid: 'minute', name: i18n.tc('base.minute') },
]

export const campaignStatuses = [
  { uuid: ORDER_STATUS.ACCEPTED, name: orderStatTranslation[ORDER_STATUS.ACCEPTED] },
  { uuid: ORDER_STATUS.PROCESSING, name: orderStatTranslation[ORDER_STATUS.PROCESSING] },
  { uuid: ORDER_STATUS.INCORRECT, name: orderStatTranslation[ORDER_STATUS.INCORRECT] },
  { uuid: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { uuid: ORDER_STATUS.SHIPPED, name: orderStatTranslation[ORDER_STATUS.SHIPPED] },
  { uuid: ORDER_STATUS.CANCELED, name: orderStatTranslation[ORDER_STATUS.CANCELED] },
  { uuid: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
  { uuid: ORDER_STATUS.NO_RANSOM, name: orderStatTranslation[ORDER_STATUS.NO_RANSOM] },
  { uuid: ORDER_STATUS.CALLBACK, name: orderStatTranslation[ORDER_STATUS.CALLBACK] },
  { uuid: ORDER_STATUS.TRASH, name: orderStatTranslation[ORDER_STATUS.TRASH] },
]

export const evaluationOptions = Array.from(EVALUATIONS_OPTIONS, ([id, name]) => ({ id, name }))

export const dashboardReportCategories = [
  {
    id: 'common',
    name: i18n.tc('exportReports.general'),
  },
  {
    id: 'call_center',
    name: i18n.tc('exportReports.callCenter'),
  },
  {
    id: 'logistic',
    name: i18n.tc('exportReports.logistics'),
  },
  {
    id: 'finance',
    name: i18n.tc('exportReports.finance'),
  },
]

export const dashboardReportFilters = [
  {
    id: 'date',
    name: i18n.tc('exportReports.period'),
  },
  {
    id: 'country',
    name: i18n.tc('callCenterPrice.filters.country'),
  },
  {
    id: 'status',
    name: i18n.tc('exportReports.selectStatus'),
  },
  {
    id: 'substatus',
    name: i18n.tc('exportReports.subStatuses'),
  },
  {
    id: 'sales_type',
    name: i18n.tc('campaign.filter.salesType'),
  },
  {
    id: 'delivery_service',
    name: i18n.tc('exportReports.deliveryService'),
  },
  {
    id: 'warehouse',
    name: i18n.tc('callCenterPrice.filters.warehouse'),
  },
]
export const itemsDashboardReports = [
  {
    id: 1,
    node: 'main',
    category: 'common',
    name: i18n.tc('exportReports.general'),
    children: [
      {
        id: 101,
        name: i18n.tc('exportReports.applicationsSpecifiedPeriod'),
        component: 'FormOrderReport',
        permission: [
          'CC_REPORT_EXPORT_ORDER',
          'LOGISTIC_REPORT_EXPORT_ORDER',
          'MNG_REPORT_EXPORT_ORDER',
        ],
      },
    ],
  },
  {
    id: 2,
    node: 'main',
    category: 'call_center',
    name: i18n.tc('exportReports.callCenter'),
    children: [
      {
        id: 201,
        name: i18n.tc('exportReports.summaryPointReport'),
        component: 'FormSummaryPointReport',
        permission: ['CC_REPORT_EXPORT_KLN_RESULT'],
      },
    ],
  },
  {
    id: 3,
    node: 'main',
    category: 'logistic',
    name: i18n.tc('exportReports.logistics'),
    children: [
      {
        id: 312,
        name: i18n.tc('exportReports.ordersListWithQuantity'),
        component: 'FormOrderListWithQuantity',
        permission: ['LOGISTIC_REPORT_CSV_ORDER'],
      },
      {
        id: 313,
        name: i18n.tc('exportReports.productList'),
        component: 'FormProductList',
        permission: ['LOGISTIC_REPORT_PRODUCT_LIST'],
      },
      {
        id: 314,
        name: i18n.tc('exportReports.applicationsSpecifiedPeriodLogistic'),
        component: 'FormOrderReportsLogistic',
        permission: ['LOGISTIC_REPORT_EXPORT_ORDER_FOR_SELECTED_PERIOD'],
      },
      {
        id: 315,
        name: i18n.tc('exportReports.writeOffProducts'),
        component: 'FormWriteOffProducts',
        permission: ['LOGISTIC_REPORT_EXPORT_WRITE_OFF_PRODUCTS'],
      },
      {
        id: 316,
        name: i18n.tc('exportReports.reportRansom'),
        component: 'FormReportRansom',
        permission: ['LOGISTIC_RANSOM_REPORT'],
      },
    ],
  },
  {
    id: 4,
    node: 'main',
    category: 'finance',
    name: i18n.tc('exportReports.finance'),
    children: [
      {
        id: 401,
        name: i18n.tc('exportReports.reportCod'),
        component: 'FormReportCod',
        permission: [
          'LOGISTIC_REPORT_COD',
          'MNG_REPORT_EXPORT_REPORT_COD'
        ],
      },
      {
        id: 402,
        name: i18n.tc('orderReportFileName.lastStatus'),
        component: 'FormLastStatus',
        permission: [
          'LOGISTIC_REPORT_EXPORT_ORDER_LAST_STATUS',
          'MNG_REPORT_EXPORT_ORDER_LAST_STATUS'
        ],
      },
    ]
  },
]

export const operatorsItems = [
  { id: 1, name: i18n.tc('base.operator') },
  { id: 0, name: i18n.tc('base.notOperator') },
  { id: null, name: i18n.tc('base.all') },
]
export const ticketTypes = [
  {
    id: 'reclamation',
    name: i18n.tc('ticketType.reclamation'),
  },
  {
    id: 'client_request',
    name: i18n.tc('ticketType.client_request'),
  },
]
export const ticketFilterTypes = [
  {
    id: 'reclamation',
    name: i18n.tc('ticketType.reclamation'),
  },
  {
    id: 'client_request',
    name: i18n.tc('ticketType.client_request'),
  },
  {
    id: null,
    name: i18n.tc('base.filtersAll'),
  },
]

export const testingItems = [
  { id: 'order:create_related_offer', name: 'Создание заказа по связаном оффере' },
  { id: 'campaign:sms_sender', name: 'Отправка SMS кампаний' },
  { id: 'delivery:synchronize_metaship_account', name: 'Синхронизация меташип аккаунтов' },
  { id: 'delivery:synchronize_metaship', name: 'Синхронизация меташип точек достаки' },
  {
    id: 'order:check_if_operator_ready_callback',
    name: 'Проврека на готовность оператора к колбеку',
  },
  { id: 'order:dispute-finish', name: 'Что то закончить. Какой то диспут. Я не в курсе' },
  { id: 'user:check_duty_manager', name: 'Проверка на наличие менеджера' },
  { id: 'ser:check_last_password_update', name: 'Проверка на то что пользователь обновлял пароль' },
  { id: 'user:check_break_time_limit', name: 'Проверка на время отдыха' },
  { id: 'user:check_ds_payment_delay', name: 'Проверка СД на задержку оплаты' },
  { id: 'user:leader_calculate_prepayment', name: 'Аванс менеджерам' },
  { id: 'user:logout_unavailable', name: 'Логаут недоступных пользователей' },
  { id: 'user:abnormal_watch_log', name: 'Проверка на аномальные просмотры' },
  { id: 'user:define_grade', name: 'Расчёт грейда' },
  { id: 'user:calculate_prepayment', name: 'Аванс операторам' },
  { id: 'user:calculate_salary', name: 'Зарплата операторам' },
]
export const priorityType = [
  {
    id: 'high',
    name: i18n.tc('ticketPriority.high'),
  },
  {
    id: 'medium',
    name: i18n.tc('ticketPriority.medium'),
  },
  {
    id: 'low',
    name: i18n.tc('ticketPriority.low'),
  },
]
export const ticketStatus = [
  {
    id: 'new',
    name: i18n.tc('ticketStatus.new'),
  },
  {
    id: 'in_progress',
    name: i18n.tc('ticketStatus.in_progress'),
  },
  {
    id: 'closed',
    name: i18n.tc('ticketStatus.closed'),
  },
]
export const itemsStatusOperator = [
  {
    id: OPERATOR_STATUS.NOT_AVAILABLE,
    name: i18n.tc('workTimeLogType.notAvailable'),
  },
  {
    id: OPERATOR_STATUS.WAIT,
    name: i18n.tc('workTimeLogType.done'),
  },
  {
    id: OPERATOR_STATUS.PERSONAL_BREAK,
    name: i18n.tc('workTimeLogType.personalBreak'),
  },
  {
    id: OPERATOR_STATUS.TECHNICAL_BREAK,
    name: i18n.tc('workTimeLogType.technicalBreak'),
  },
  // {
  //   id: OPERATOR_STATUS.MANUAL_CALL,
  //   name: i18n.tc('workTimeLogType.manualCall'),
  // },
  {
    id: OPERATOR_STATUS.EDUCATION,
    name: i18n.tc('workTimeLogType.education'),
  },
  {
    id: OPERATOR_STATUS.CONSULTATION,
    name: i18n.tc('workTimeLogType.consultation'),
  },
  {
    id: OPERATOR_STATUS.KLN,
    name: i18n.tc('workTimeLogType.kln'),
  },
  {
    id: OPERATOR_STATUS.ORDER_PROCESSING,
    name: i18n.tc('workTimeLogType.orderProcessing'),
    $isDisabled: true,
    isHide: true,
  },
]

export const itemsStatusOperatorFilter = [
  ...itemsStatusOperator.slice(0, -1),
  {
    id: 'offline',
    name: i18n.tc('workTimeLogType.offline'),
  },
]

export const itemsLogAuth = [
  {
    uuid: 'create',
    name: 'create',
  },
  {
    uuid: 'update',
    name: 'update',
  },
  {
    uuid: 'remove',
    name: 'remove',
  },
  {
    uuid: 'watch_number',
    name: 'watch_number',
  },
  {
    uuid: 'login_success',
    name: i18n.tc('logAuth.login_success'),
    event: 'log_auth',
  },
  {
    uuid: 'login_fail',
    name: i18n.tc('logAuth.login_fail'),
    event: 'log_auth',
  },
  {
    uuid: 'login_logout',
    name: i18n.tc('logAuth.login_logout'),
    event: 'log_auth',
  },
]

export const orderStatusesForSms = [
  { uuid: ORDER_STATUS.APPROVED, name: orderStatTranslation[ORDER_STATUS.APPROVED] },
  { uuid: ORDER_STATUS.SHIPPED, name: orderStatTranslation[ORDER_STATUS.SHIPPED] },
  { uuid: ORDER_STATUS.AWAITING_RANSOM, name: orderStatTranslation[ORDER_STATUS.AWAITING_RANSOM] },
  { uuid: ORDER_STATUS.DELIVERED, name: orderStatTranslation[ORDER_STATUS.DELIVERED] },
]

export const itemsTimeType = [
  { uuid: 'total', name: i18n.tc('workTime.reflectionSumNumber') },
  { uuid: 'avg', name: i18n.tc('workTime.reflectionAvg') },
]
export const filterOrderFavorites = [
  { value: 0, label: i18n.tc('base.all') },
  { value: 1, label: i18n.tc('order.favorites') },
]

export const klnStatuses = [
  {
    uuid: KLN_STATUS.FINISHED,
    name: KLN_TRANSACTION[KLN_STATUS.FINISHED],
    color: KLN_COLOR[KLN_STATUS.FINISHED],
  },
  {
    uuid: KLN_STATUS.PENDING,
    name: KLN_TRANSACTION[KLN_STATUS.PENDING],
    color: KLN_COLOR[KLN_STATUS.PENDING],
  },
  {
    uuid: KLN_STATUS.CREATED,
    name: KLN_TRANSACTION[KLN_STATUS.CREATED],
    color: KLN_COLOR[KLN_STATUS.CREATED],
  },
]

export const systemMacrosList = [
  {
    id: SYSTEM_MACROS.MAIN,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.MAIN],
  },
  {
    id: SYSTEM_MACROS.SUBSTITUTE,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.SUBSTITUTE],
  },
  {
    id: SYSTEM_MACROS.ALTERNATE,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.ALTERNATE],
  },
  {
    id: SYSTEM_MACROS.ADDITIONAL_CROSS,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.ADDITIONAL_CROSS],
  },
  {
    id: SYSTEM_MACROS.AWARE_CROSS,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.AWARE_CROSS],
  },
  {
    id: SYSTEM_MACROS.ANOTHER,
    label: SYSTEM_MACROS_TRANSACTION[SYSTEM_MACROS.ANOTHER],
  },
]

export const errorsInCalcFinancialReport = [
  { id: null, name: i18n.tc('base.all') },
  { id: 1, name: i18n.tc('financialReport.withErrorsOnly') },
  { id: 0, name: i18n.tc('financialReport.withoutErrorsOnly') },
]

export const itemsGender = [
  {
    uuid: 'male',
    name: i18n.tc('averageCheck.male'),
  },
  {
    uuid: 'female',
    name: i18n.tc('averageCheck.female'),
  },
  {
    uuid: null,
    name: i18n.tc('base.all'),
  },
]

export const itemsFilterAges = [
  { uuid: 10, name: i18n.tc('ages.less15') },
  { uuid: 15, name: '15-20' },
  { uuid: 20, name: '20-25' },
  { uuid: 25, name: '25-30' },
  { uuid: 30, name: '30-35' },
  { uuid: 35, name: '35-40' },
  { uuid: 40, name: '40-45' },
  { uuid: 45, name: '45-50' },
  { uuid: 50, name: '50-55' },
  { uuid: 55, name: '55-60' },
  { uuid: 60, name: '60-65' },
  { uuid: 65, name: '65-70' },
  { uuid: 70, name: '70-75' },
  { uuid: 75, name: '75-80' },
  { uuid: 80, name: '80-85' },
  { uuid: 85, name: '85-90' },
  { uuid: 90, name: i18n.tc('ages.more90') },
]

export const itemsFilterAverageCheck = [
  {
    id: AVERAGE_CHECK.WEEK,
    name: i18n.tc('averageCheck.select.week'),
  },
  {
    id: AVERAGE_CHECK.MONTH,
    name: i18n.tc('averageCheck.select.month'),
  },
  {
    id: AVERAGE_CHECK.OFFER,
    name: i18n.tc('averageCheck.select.offer'),
  },
  {
    id: AVERAGE_CHECK.OFFER_GROUP,
    name: i18n.tc('averageCheck.select.offer_group'),
  },
  {
    id: AVERAGE_CHECK.OFFER_NICHE,
    name: i18n.tc('averageCheck.select.offer_niche'),
  },
  {
    id: AVERAGE_CHECK.COUNTRY,
    name: i18n.tc('averageCheck.select.countries'),
  },
  {
    id: AVERAGE_CHECK.REGION,
    name: i18n.tc('averageCheck.select.region'),
  },
  {
    id: AVERAGE_CHECK.CITY,
    name: i18n.tc('averageCheck.select.city'),
  },
  {
    id: AVERAGE_CHECK.OPERATOR_GROUPS,
    name: i18n.tc('averageCheck.select.operator_group'),
  },
  {
    id: AVERAGE_CHECK.OPERATOR,
    name: i18n.tc('averageCheck.select.operator'),
  },
  {
    id: AVERAGE_CHECK.LANDING,
    name: i18n.tc('averageCheck.select.landing'),
  },
  {
    id: AVERAGE_CHECK.WEB,
    name: i18n.tc('averageCheck.select.web'),
  },
  {
    id: AVERAGE_CHECK.REJECT_REASON,
    name: i18n.tc('averageCheck.select.reject_reason'),
  },
  {
    id: AVERAGE_CHECK.DELIVERY_SERVICE,
    name: i18n.tc('averageCheck.select.delivery_service'),
  },
  {
    id: AVERAGE_CHECK.SALES_MODEL,
    name: i18n.tc('averageCheck.select.sales_model'),
  },
  {
    id: AVERAGE_CHECK.PRODUCT_TYPE,
    name: i18n.tc('averageCheck.select.product_type'),
  },
  {
    id: AVERAGE_CHECK.AGE,
    name: i18n.tc('averageCheck.select.age'),
  },
  {
    id: AVERAGE_CHECK.GENDER,
    name: i18n.tc('averageCheck.select.gender'),
  },
  {
    id: AVERAGE_CHECK.WAREHOUSE,
    name: i18n.tc('averageCheck.select.warehouse'),
  },
]

export const itemsFilterApproveSpeed = [
  {
    id: APPROVE_SPEED.HOURS,
    name: i18n.tc('approveSpeed.hours'),
  },
  {
    id: APPROVE_SPEED.DAY,
    name: i18n.tc('approveSpeed.day'),
  },
  {
    id: APPROVE_SPEED.WEEK,
    name: i18n.tc('approveSpeed.week'),
  },
  {
    id: APPROVE_SPEED.MONTH,
    name: i18n.tc('approveSpeed.month'),
  },
  {
    id: APPROVE_SPEED.OFFER,
    name: i18n.tc('approveSpeed.offer'),
  },
  {
    id: APPROVE_SPEED.OFFER_GROUP,
    name: i18n.tc('approveSpeed.groupOffer'),
  },
  {
    id: APPROVE_SPEED.OFFER_NICHE,
    name: i18n.tc('approveSpeed.offerNiches'),
  },
  {
    id: APPROVE_SPEED.COUNTRY,
    name: i18n.tc('approveSpeed.country'),
  },
  {
    id: APPROVE_SPEED.REGION,
    name: i18n.tc('approveSpeed.region'),
  },
  {
    id: APPROVE_SPEED.USER_GROUP,
    name: i18n.tc('approveSpeed.operatorGroup'),
  },
  {
    id: APPROVE_SPEED.OPERATOR,
    name: i18n.tc('approveSpeed.operator'),
  },
  {
    id: APPROVE_SPEED.LANDING,
    name: i18n.tc('approveSpeed.landing'),
  },
  {
    id: APPROVE_SPEED.WEB,
    name: i18n.tc('approveSpeed.web'),
  },
  {
    id: APPROVE_SPEED.DELIVERY_SERVICE,
    name: i18n.tc('approveSpeed.deliveryService'),
  },
  {
    id: APPROVE_SPEED.SALES_MODEL,
    name: i18n.tc('approveSpeed.salesModel'),
  },
  {
    id: APPROVE_SPEED.SALES_TYPE,
    name: i18n.tc('approveSpeed.salesType'),
  },
  {
    id: APPROVE_SPEED.AGE,
    name: i18n.tc('approveSpeed.age'),
  },
  {
    id: APPROVE_SPEED.GENDER,
    name: i18n.tc('approveSpeed.gender'),
  },
]

export const filterDateByApproveSpeed = [
  {
    id: 'created_at',
    name: i18n.tc('approveSpeed.filterDateBy.createdAt'),
  },
  {
    id: 'approve',
    name: i18n.tc('approveSpeed.filterDateBy.approve'),
  },
  {
    id: 'processing',
    name: i18n.tc('approveSpeed.filterDateBy.processing'),
  },
]

export const periodsApproveSpeed = [
  {
    id: 'hour',
    name: i18n.tc('approveSpeed.periods.hour'),
  },
  {
    id: 'day',
    name: i18n.tc('approveSpeed.periods.day'),
  },
  {
    id: 'call',
    name: i18n.tc('approveSpeed.periods.call'),
  },
]
export const itemsFilterDateBy = [
  {
    id: 'created_at',
    name: i18n.tc('averageCheck.filterDateBy.createdAt'),
  },
  {
    id: 'processing_date',
    name: i18n.tc('averageCheck.filterDateBy.processingDate'),
  },
  {
    id: 'approve_date',
    name: i18n.tc('averageCheck.filterDateBy.approveDate'),
  },
  {
    id: 'shipped_date',
    name: i18n.tc('averageCheck.filterDateBy.shippedDate'),
  },
  {
    id: 'ransom_date',
    name: i18n.tc('averageCheck.filterDateBy.ransomDate'),
  },
]

export const itemsFilterDateByHanging = [
  {
    id: 'created_at',
    name: i18n.tc('hangingRansom.filterDateBy.createdAt'),
  },
  {
    id: 'approve',
    name: i18n.tc('hangingRansom.filterDateBy.approve'),
  },
  {
    id: 'shipped',
    name: i18n.tc('hangingRansom.filterDateBy.shipped'),
  },
  {
    id: 'delivered',
    name: i18n.tc('hangingRansom.filterDateBy.delivered'),
  },
]

export const itemsFilterHangingRansom = [
  {
    id: HANGING_RANSOM.MONTH,
    name: i18n.tc('hangingRansom.filtersSelect.month'),
  },
  {
    id: HANGING_RANSOM.COUNTRY,
    name: i18n.tc('hangingRansom.filtersSelect.country'),
  },
  {
    id: HANGING_RANSOM.REGION,
    name: i18n.tc('hangingRansom.filtersSelect.region'),
  },
  {
    id: HANGING_RANSOM.OFFER_GROUP,
    name: i18n.tc('hangingRansom.filtersSelect.offer_group'),
  },
  {
    id: HANGING_RANSOM.OFFER_NICHE,
    name: i18n.tc('hangingRansom.filtersSelect.offer_niche'),
  },
  {
    id: HANGING_RANSOM.DELIVERY_SERVICE,
    name: i18n.tc('hangingRansom.filtersSelect.delivery_service'),
  },
  {
    id: HANGING_RANSOM.SALES_MODEL,
    name: i18n.tc('hangingRansom.filtersSelect.sales_model'),
  },
  {
    id: 'offer',
    name: i18n.tc('hangingRansom.filtersSelect.offer'),
  },
]

export const itemsOrderReports = [
  {
    id: 'offer',
    name: i18n.tc('exportReports.fields.offer'),
  },
  {
    id: 'sales_model',
    name: i18n.tc('exportReports.fields.salesModel'),
  },
  {
    id: 'niche',
    name: i18n.tc('exportReports.fields.niche'),
  },
  {
    id: 'create_date',
    name: i18n.tc('exportReports.fields.dateCreate'),
  },
  {
    id: 'approve_date',
    name: i18n.tc('exportReports.fields.dateApprove'),
  },
  {
    id: 'send_date',
    name: i18n.tc('exportReports.fields.sendDate'),
  },
  {
    id: 'ransom_date',
    name: i18n.tc('exportReports.fields.dateRansom'),
  },
  {
    id: 'fio',
    name: i18n.tc('exportReports.fields.fio'),
  },
  {
    id: 'gender',
    name: i18n.tc('exportReports.fields.gender'),
  },
  {
    id: 'age',
    name: i18n.tc('exportReports.fields.age'),
  },
  {
    id: 'birth_date',
    name: i18n.tc('exportReports.fields.birthDate'),
  },
  {
    id: 'first_approve_date',
    name: i18n.tc('exportReports.fields.firstApproveDate'),
  },
  {
    id: 'comment',
    name: i18n.tc('exportReports.fields.comment'),
  },
  {
    id: 'country',
    name: i18n.tc('exportReports.fields.country'),
  },
  {
    id: 'district',
    name: i18n.tc('exportReports.fields.district'),
  },
  {
    id: 'city',
    name: i18n.tc('exportReports.fields.city'),
  },
  {
    id: 'address',
    name: i18n.tc('exportReports.fields.address'),
  },
  {
    id: 'delivery_type',
    name: i18n.tc('exportReports.fields.deliveryType'),
  },
  {
    id: 'delivery_service',
    name: i18n.tc('exportReports.fields.deliveryService'),
  },
  {
    id: 'available_delivery_service_type',
    name: i18n.tc('exportReports.fields.availableDeliveryServiceType'),
  },
  {
    id: 'delivery_point',
    name: i18n.tc('exportReports.fields.deliveryPoint'),
  },
  {
    id: 'delivery_date',
    name: i18n.tc('exportReports.fields.deliveryDate'),
  },
  {
    id: 'track_number',
    name: i18n.tc('exportReports.fields.trackNumber'),
  },
  {
    id: 'order_price',
    name: i18n.tc('exportReports.fields.orderPrice'),
  },
  {
    id: 'web',
    name: i18n.tc('exportReports.fields.web'),
  },
  {
    id: 'landing',
    name: i18n.tc('exportReports.fields.landing'),
  },
  {
    id: 'sales_type',
    name: i18n.tc('exportReports.fields.salesType'),
  },
  {
    id: 'status',
    name: i18n.tc('exportReports.fields.status'),
  },
  {
    id: 'substatus',
    name: i18n.tc('exportReports.fields.substatus'),
  },
  {
    id: 'main_operator',
    name: i18n.tc('exportReports.fields.mainOperator'),
  },
  {
    id: 'products',
    name: i18n.tc('exportReports.fields.product'),
  },
  {
    id: 'phone',
    name: i18n.tc('exportReports.fields.phone'),
  },
  {
    id: 'warehouse',
    name: i18n.tc('exportReports.fields.warehouse'),
  },
  {
    id: 'prepayment',
    name: i18n.tc('exportReports.fields.prepayment'),
  },
  {
    id: 'logs',
    name: i18n.tc('exportReports.fields.logs'),
  },
]

export const exportCustomReportFields = [
  ...itemsOrderReports,
  {
    id: 'postal_code',
    name: i18n.tc('exportReports.fields.postalCode'),
  },
  {
    id: 'cpa_id',
    name: i18n.tc('exportReports.fields.cpaId'),
  },
  {
    id: 'currency',
    name: i18n.tc('exportReports.fields.currency'),
  },
  {
    id: 'name',
    name: i18n.tc('exportReports.fields.name'),
  },
  {
    id: 'last_name',
    name: i18n.tc('exportReports.fields.lastName'),
  },
  {
    id: 'patronymic',
    name: i18n.tc('exportReports.fields.patronymic'),
  },
  {
    id: 'street',
    name: i18n.tc('exportReports.fields.street'),
  },
  {
    id: 'house',
    name: i18n.tc('exportReports.fields.house'),
  },
  {
    id: 'apartment',
    name: i18n.tc('exportReports.fields.apartment'),
  },
  {
    id: 'offer_id',
    name: i18n.tc('exportReports.fields.offerId'),
  },
  {
    id: 'order_id',
    name: i18n.tc('exportReports.fields.orderId'),
  },
]

export const legalPersonsStatsTypes = [
  {
    id: LEGAL_STATS_TYPE.WAREHOUSE,
    name: i18n.tc('legalPersonsStats.levelTypes.warehouse'),
  },
  {
    id: LEGAL_STATS_TYPE.ORGANIZATION,
    name: i18n.tc('legalPersonsStats.levelTypes.organization'),
  },
  {
    id: LEGAL_STATS_TYPE.DELIVERY_SERVICE,
    name: i18n.tc('legalPersonsStats.levelTypes.deliveryService'),
  },
  {
    id: LEGAL_STATS_TYPE.DELIVERY_TYPE,
    name: i18n.tc('legalPersonsStats.levelTypes.deliveryType'),
  },
  {
    id: LEGAL_STATS_TYPE.OFFER,
    name: i18n.tc('legalPersonsStats.levelTypes.offer'),
  },
  {
    id: LEGAL_STATS_TYPE.PRODUCT,
    name: i18n.tc('legalPersonsStats.levelTypes.product'),
  },
  {
    id: LEGAL_STATS_TYPE.COUNTRY,
    name: i18n.tc('legalPersonsStats.levelTypes.country'),
  },
  {
    id: LEGAL_STATS_TYPE.REGION,
    name: i18n.tc('legalPersonsStats.levelTypes.region'),
  },
]

export const itemsFilterDeliveryServiceAnalytic = [
  {
    id: DELIVERY_SERVICE_ANALYTIC.OFFER,
    name: i18n.tc('deliveryServiceAnalytic.select.offer'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.OFFER_GROUP,
    name: i18n.tc('deliveryServiceAnalytic.select.offer_group'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.OFFER_NICHE,
    name: i18n.tc('deliveryServiceAnalytic.select.offer_niche'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.COUNTRY,
    name: i18n.tc('deliveryServiceAnalytic.select.country'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.REGION,
    name: i18n.tc('deliveryServiceAnalytic.select.region'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.CITY,
    name: i18n.tc('deliveryServiceAnalytic.select.city'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.OPERATOR_GROUPS,
    name: i18n.tc('deliveryServiceAnalytic.select.user_group'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.OPERATOR,
    name: i18n.tc('deliveryServiceAnalytic.select.operator'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.LANDING,
    name: i18n.tc('deliveryServiceAnalytic.select.landing'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.WEB,
    name: i18n.tc('deliveryServiceAnalytic.select.web'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.DELIVERY_SERVICE,
    name: i18n.tc('deliveryServiceAnalytic.select.delivery_service'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.SALES_MODEL,
    name: i18n.tc('deliveryServiceAnalytic.select.sales_model'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.PRODUCT_TYPE,
    name: i18n.tc('deliveryServiceAnalytic.select.product_type'),
  },
  {
    id: DELIVERY_SERVICE_ANALYTIC.WAREHOUSE,
    name: i18n.tc('deliveryServiceAnalytic.select.warehouse'),
  },
]

export const itemsGroupDate = [
  {
    id: 'year',
    name: i18n.tc('deliveryServiceAnalytic.groupDateBy.year'),
  },
  {
    id: 'quarter',
    name: i18n.tc('deliveryServiceAnalytic.groupDateBy.quarter'),
  },
  {
    id: 'month',
    name: i18n.tc('deliveryServiceAnalytic.groupDateBy.month'),
  },
  {
    id: 'week',
    name: i18n.tc('deliveryServiceAnalytic.groupDateBy.week'),
  },
  {
    id: 'day',
    name: i18n.tc('deliveryServiceAnalytic.groupDateBy.day'),
  },
]

export const itemsTotalType = [
  {
    id: 'total_all',
    name: i18n.tc('deliveryServiceAnalytic.totalType.totalAll'),
  },
  {
    id: 'total_current',
    name: i18n.tc('deliveryServiceAnalytic.totalType.totalCurrent'),
  },
]

export const itemsFilterDateByDelivery = [
  {
    id: 'created_at',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.createdAt'),
  },
  {
    id: 'approve',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.approveDate'),
  },
  {
    id: 'first_approve',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.firstApproveDate'),
  },
  {
    id: 'shipped',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.shippedDate'),
  },
  {
    id: 'delivered',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.deliveryDate'),
  },
  {
    id: 'last_status',
    name: i18n.tc('deliveryServiceAnalytic.filterDateBy.lastStatus'),
  },
]

export const itemsRejectionFirstGroup = [
  {
    id: REJECTED_STATISTICS.STATUS,
    name: i18n.tc('rejectionStatistics.filtersGroup.status_group'),
  },
  {
    id: REJECTED_STATISTICS.STATUS_REASON,
    name: i18n.tc('rejectionStatistics.filtersGroup.reject_reason'),
  },
  {
    id: REJECTED_STATISTICS.DAY,
    name: i18n.tc('rejectionStatistics.filtersGroup.day'),
  },
  {
    id: REJECTED_STATISTICS.WEEK,
    name: i18n.tc('rejectionStatistics.filtersGroup.week'),
  },
  {
    id: REJECTED_STATISTICS.MONTH,
    name: i18n.tc('rejectionStatistics.filtersGroup.month'),
  },
  {
    id: REJECTED_STATISTICS.OFFER,
    name: i18n.tc('rejectionStatistics.filtersGroup.offer'),
  },
  {
    id: REJECTED_STATISTICS.OFFER_GROUP,
    name: i18n.tc('rejectionStatistics.filtersGroup.offer_group'),
  },
  {
    id: REJECTED_STATISTICS.OFFER_NICHE,
    name: i18n.tc('rejectionStatistics.filtersGroup.offer_niche'),
  },
  {
    id: REJECTED_STATISTICS.USER_GROUP,
    name: i18n.tc('rejectionStatistics.filtersGroup.operator_groups'),
  },
  {
    id: REJECTED_STATISTICS.COUNTRY,
    name: i18n.tc('rejectionStatistics.filtersGroup.country'),
  },
  {
    id: REJECTED_STATISTICS.WEB,
    name: i18n.tc('rejectionStatistics.filtersGroup.web'),
  },
  {
    id: REJECTED_STATISTICS.OPERATOR,
    name: i18n.tc('rejectionStatistics.filtersGroup.operator'),
  },
  {
    id: REJECTED_STATISTICS.SALES_MODEL,
    name: i18n.tc('rejectionStatistics.filtersGroup.sales_model'),
  },
  {
    id: REJECTED_STATISTICS.SALES_TYPE,
    name: i18n.tc('rejectionStatistics.filtersGroup.sales_type'),
  },
]

export const itemsRejectionFilterDateBy = [
  {
    id: 'created_at',
    name: i18n.tc('averageCheck.filterDateBy.createdAt'),
  },
  {
    id: 'first_approve',
    name: i18n.tc('averageCheck.filterDateBy.approveDate'),
  },
  {
    id: 'processing',
    name: i18n.tc('averageCheck.filterDateBy.processingDate'),
  },
]

export const itemsRejectionStatuses = [
  {
    id: 'canceled',
    name: i18n.tc('rejectionStatistics.statuses.canceled'),
  },
  {
    id: 'callback',
    name: i18n.tc('rejectionStatistics.statuses.callback'),
  },
  {
    id: 'trash',
    name: i18n.tc('rejectionStatistics.statuses.trash'),
  },
  {
    id: null,
    name: i18n.tc('base.filtersAll'),
  },
]

export const itemsFilterCallCentre = [
  {
    id: CALL_CENTRE_STATISTICS.HOUR,
    name: i18n.tc('callCentreStatistics.select.hour'),
  },
  {
    id: CALL_CENTRE_STATISTICS.DAY,
    name: i18n.tc('callCentreStatistics.select.day'),
  },
  {
    id: CALL_CENTRE_STATISTICS.WEEK,
    name: i18n.tc('callCentreStatistics.select.week'),
  },
  {
    id: CALL_CENTRE_STATISTICS.MONTH,
    name: i18n.tc('callCentreStatistics.select.month'),
  },
  {
    id: CALL_CENTRE_STATISTICS.OFFER,
    name: i18n.tc('callCentreStatistics.select.offer'),
  },
  {
    id: CALL_CENTRE_STATISTICS.OFFER_GROUP,
    name: i18n.tc('callCentreStatistics.select.offer_group'),
  },
  {
    id: CALL_CENTRE_STATISTICS.OFFER_NICHE,
    name: i18n.tc('callCentreStatistics.select.offer_niche'),
  },
  {
    id: CALL_CENTRE_STATISTICS.COUNTRY,
    name: i18n.tc('callCentreStatistics.select.country'),
  },
  {
    id: CALL_CENTRE_STATISTICS.REGION,
    name: i18n.tc('callCentreStatistics.select.region'),
  },
  {
    id: CALL_CENTRE_STATISTICS.CITY,
    name: i18n.tc('callCentreStatistics.select.city'),
  },
  {
    id: CALL_CENTRE_STATISTICS.OPERATOR_GROUPS,
    name: i18n.tc('callCentreStatistics.select.operator_groups'),
  },
  {
    id: CALL_CENTRE_STATISTICS.OPERATOR,
    name: i18n.tc('callCentreStatistics.select.operator'),
  },
  {
    id: CALL_CENTRE_STATISTICS.LANDING,
    name: i18n.tc('callCentreStatistics.select.landing'),
  },
  {
    id: CALL_CENTRE_STATISTICS.WEB,
    name: i18n.tc('callCentreStatistics.select.web'),
  },
  {
    id: CALL_CENTRE_STATISTICS.REJECT_REASON,
    name: i18n.tc('callCentreStatistics.select.reject_reason'),
  },
  {
    id: CALL_CENTRE_STATISTICS.DELIVERY_SERVICE,
    name: i18n.tc('callCentreStatistics.select.delivery_service'),
  },
  {
    id: CALL_CENTRE_STATISTICS.SALES_MODEL,
    name: i18n.tc('callCentreStatistics.select.sales_model'),
  },
  {
    id: CALL_CENTRE_STATISTICS.SALES_TYPE,
    name: i18n.tc('callCentreStatistics.select.sales_type'),
  },
  {
    id: CALL_CENTRE_STATISTICS.PRODUCT_TYPE,
    name: i18n.tc('callCentreStatistics.select.product_type'),
  },
  {
    id: CALL_CENTRE_STATISTICS.AGE,
    name: i18n.tc('callCentreStatistics.select.age'),
  },
  {
    id: CALL_CENTRE_STATISTICS.GENDER,
    name: i18n.tc('callCentreStatistics.select.gender'),
  },
]

export const itemsFilterDateByKC = [
  {
    id: 'created_at',
    name: i18n.tc('callCentreStatistics.filterDateBy.createdAt'),
  },
  {
    id: 'approve',
    name: i18n.tc('callCentreStatistics.filterDateBy.approve'),
  },
  {
    id: 'processing',
    name: i18n.tc('callCentreStatistics.filterDateBy.processing'),
  },
  {
    id: 'shipped',
    name: i18n.tc('callCentreStatistics.filterDateBy.shipped'),
  },
  {
    id: 'delivered',
    name: i18n.tc('callCentreStatistics.filterDateBy.delivered'),
  },
]

export const ccStatisticsDashboardGroupItems = [
  {
    id: 'day',
    name: i18n.tc('ccStatisticsDashboard.groupBy.day'),
  },
  {
    id: 'week',
    name: i18n.tc('ccStatisticsDashboard.groupBy.week'),
  },
  {
    id: 'month',
    name: i18n.tc('ccStatisticsDashboard.groupBy.month'),
  },
]
export const itemsFilterDateByBuyback = [
  {
    id: 'created_at',
    name: i18n.tc('buybackDynamics.filterDateBy.created_at'),
  },
  {
    id: 'approve',
    name: i18n.tc('buybackDynamics.filterDateBy.approve'),
  },
  {
    id: 'first_approve',
    name: i18n.tc('buybackDynamics.filterDateBy.first_approve'),
  },
  {
    id: 'shipped',
    name: i18n.tc('buybackDynamics.filterDateBy.shipped'),
  },
  {
    id: 'awaiting_ransom',
    name: i18n.tc('buybackDynamics.filterDateBy.awaiting_ransom'),
  },
]

export const itemsCallTime = [
  {
    id: null,
    name: i18n.tc('base.all'),
  },
  {
    id: 4,
    name: i18n.tc('klnAssessment.callTimeItems.low'),
  },
  {
    id: 6,
    name: i18n.tc('klnAssessment.callTimeItems.middle'),
  },
  {
    id: 10,
    name: i18n.tc('klnAssessment.callTimeItems.top'),
  },
]

export const itemsFilterReconfirmation = [
  {
    id: RECONFIRMATION_STATISTICS.DAY,
    name: i18n.tc('reconfirmationStatistic.select.day'),
  },
  {
    id: RECONFIRMATION_STATISTICS.WEEK,
    name: i18n.tc('reconfirmationStatistic.select.week'),
  },
  {
    id: RECONFIRMATION_STATISTICS.MONTH,
    name: i18n.tc('reconfirmationStatistic.select.month'),
  },
  {
    id: RECONFIRMATION_STATISTICS.OFFER,
    name: i18n.tc('reconfirmationStatistic.select.offer'),
  },
  {
    id: RECONFIRMATION_STATISTICS.OFFER_GROUP,
    name: i18n.tc('reconfirmationStatistic.select.offer_group'),
  },
  {
    id: RECONFIRMATION_STATISTICS.OFFER_NICHE,
    name: i18n.tc('reconfirmationStatistic.select.offer_niche'),
  },
  {
    id: RECONFIRMATION_STATISTICS.OPERATOR,
    name: i18n.tc('reconfirmationStatistic.select.operator'),
  },
  {
    id: RECONFIRMATION_STATISTICS.OPERATOR_GROUPS,
    name: i18n.tc('reconfirmationStatistic.select.operator_groups'),
  },
  {
    id: RECONFIRMATION_STATISTICS.DELIVERY_SERVICE,
    name: i18n.tc('reconfirmationStatistic.select.delivery_service'),
  },
  {
    id: RECONFIRMATION_STATISTICS.DELIVERY_TYPE,
    name: i18n.tc('reconfirmationStatistic.select.delivery_type'),
  },
  {
    id: RECONFIRMATION_STATISTICS.SALES_MODEL,
    name: i18n.tc('reconfirmationStatistic.select.sales_model'),
  },
  {
    id: RECONFIRMATION_STATISTICS.SALES_TYPE,
    name: i18n.tc('reconfirmationStatistic.select.sales_type'),
  },
  {
    id: RECONFIRMATION_STATISTICS.AGE,
    name: i18n.tc('reconfirmationStatistic.select.age'),
  },
  {
    id: RECONFIRMATION_STATISTICS.GENDER,
    name: i18n.tc('reconfirmationStatistic.select.gender'),
  },
]

export const itemsFilterCallStatistics = [
  {
    id: CALL_STATISTICS.DAY,
    name: i18n.tc('callStatistics.select.day'),
  },
  {
    id: CALL_STATISTICS.WEEK,
    name: i18n.tc('callStatistics.select.week'),
  },
  {
    id: CALL_STATISTICS.MONTH,
    name: i18n.tc('callStatistics.select.month'),
  },
  {
    id: CALL_STATISTICS.COUNTRY,
    name: i18n.tc('callStatistics.select.country'),
  },
  {
    id: CALL_STATISTICS.OFFER,
    name: i18n.tc('callStatistics.select.offer'),
  },
  {
    id: CALL_STATISTICS.OPERATOR_GROUPS,
    name: i18n.tc('callStatistics.select.operator_groups'),
  },
  {
    id: CALL_STATISTICS.OPERATOR,
    name: i18n.tc('callStatistics.select.operator'),
  },
  {
    id: CALL_STATISTICS.SALES_TYPE,
    name: i18n.tc('callStatistics.select.sales_type'),
  },
]

export const itemsFilterCallStatisticsOld = [
  {
    id: CALL_STATISTICS.DAY,
    name: i18n.tc('callStatistics.select.day'),
  },
  {
    id: CALL_STATISTICS.WEEK,
    name: i18n.tc('callStatistics.select.week'),
  },
  {
    id: CALL_STATISTICS.MONTH,
    name: i18n.tc('callStatistics.select.month'),
  },
  {
    id: CALL_STATISTICS.OFFER,
    name: i18n.tc('callStatistics.select.offer'),
  },
  {
    id: CALL_STATISTICS.OFFER_GROUP,
    name: i18n.tc('callStatistics.select.offer_group'),
  },
  {
    id: CALL_STATISTICS.OFFER_NICHE,
    name: i18n.tc('callStatistics.select.offer_niche'),
  },
  {
    id: CALL_STATISTICS.OPERATOR,
    name: i18n.tc('callStatistics.select.operator'),
  },
  {
    id: CALL_STATISTICS.OPERATOR_GROUPS,
    name: i18n.tc('callStatistics.select.operator_groups'),
  },
  {
    id: CALL_STATISTICS.DELIVERY_SERVICE,
    name: i18n.tc('callStatistics.select.delivery_service'),
  },
  {
    id: CALL_STATISTICS.SALES_MODEL,
    name: i18n.tc('callStatistics.select.sales_model'),
  },
  {
    id: CALL_STATISTICS.SALES_TYPE,
    name: i18n.tc('callStatistics.select.sales_type'),
  },
  {
    id: CALL_STATISTICS.AGE,
    name: i18n.tc('callStatistics.select.age'),
  },
  {
    id: CALL_STATISTICS.GENDER,
    name: i18n.tc('callStatistics.select.gender'),
  },
  {
    id: CALL_STATISTICS.WEB,
    name: i18n.tc('callStatistics.select.web'),
  },
]

export const itemsFilterDateByCall = [
  {
    id: 'auto',
    name: i18n.tc('callStatistics.filterDateBy.auto'),
  },
  {
    id: 'manual',
    name: i18n.tc('callStatistics.filterDateBy.manual'),
  },
]

export const itemsFilterFinancialReport = [
  {
    id: FINANCIAL_REPORT.DAY,
    name: i18n.tc('financialReport.select.day'),
  },
  {
    id: FINANCIAL_REPORT.WEEK,
    name: i18n.tc('financialReport.select.week'),
  },
  {
    id: FINANCIAL_REPORT.MONTH,
    name: i18n.tc('financialReport.select.month'),
  },
  {
    id: FINANCIAL_REPORT.OFFER,
    name: i18n.tc('financialReport.select.offer'),
  },
  {
    id: FINANCIAL_REPORT.OFFER_GROUP,
    name: i18n.tc('financialReport.select.offer_group'),
  },
  {
    id: FINANCIAL_REPORT.OFFER_NICHE,
    name: i18n.tc('financialReport.select.offer_niche'),
  },
  {
    id: FINANCIAL_REPORT.OPERATOR_GROUPS,
    name: i18n.tc('financialReport.select.operator_groups'),
  },
  {
    id: FINANCIAL_REPORT.OPERATOR,
    name: i18n.tc('financialReport.select.operator'),
  },
  {
    id: FINANCIAL_REPORT.DELIVERY_SERVICE,
    name: i18n.tc('financialReport.select.delivery_service'),
  },
  {
    id: FINANCIAL_REPORT.DELIVERY_TYPE,
    name: i18n.tc('financialReport.select.delivery_type'),
  },
  {
    id: FINANCIAL_REPORT.COUNTRY,
    name: i18n.tc('financialReport.select.country'),
  },
  {
    id: FINANCIAL_REPORT.REGION,
    name: i18n.tc('financialReport.select.region'),
  },
  {
    id: FINANCIAL_REPORT.CITY,
    name: i18n.tc('financialReport.select.city'),
  },
  {
    id: FINANCIAL_REPORT.SALES_MODEL,
    name: i18n.tc('financialReport.select.sales_model'),
  },
  {
    id: FINANCIAL_REPORT.SALES_TYPE,
    name: i18n.tc('financialReport.select.sales_type'),
  },
  {
    id: FINANCIAL_REPORT.AGE,
    name: i18n.tc('financialReport.select.age'),
  },
  {
    id: FINANCIAL_REPORT.GENDER,
    name: i18n.tc('financialReport.select.gender'),
  },
  {
    id: FINANCIAL_REPORT.WEB,
    name: i18n.tc('financialReport.select.web'),
  },
  {
    id: FINANCIAL_REPORT.LANDING,
    name: i18n.tc('financialReport.select.landing'),
  },
  {
    id: FINANCIAL_REPORT.ORGANIZATION,
    name: i18n.tc('financialReport.select.organization'),
  },
  {
    id: FINANCIAL_REPORT.PRODUCT_TYPE,
    name: i18n.tc('financialReport.select.product_type'),
  },
  {
    id: FINANCIAL_REPORT.WAREHOUSE,
    name: i18n.tc('financialReport.select.warehouse'),
  },
]

export const itemsFilterDateByFinancialReport = [
  {
    id: 'created_at',
    name: i18n.tc('financialReport.filterDateBy.createdAt'),
  },
  {
    id: 'approve',
    name: i18n.tc('financialReport.filterDateBy.approve'),
  },
  {
    id: 'shipped',
    name: i18n.tc('financialReport.filterDateBy.shipped'),
  },
  {
    id: 'delivered',
    name: i18n.tc('financialReport.filterDateBy.delivered'),
  },
]

export const itemsFilterDateByAverage = [
  {
    id: 'created_at',
    name: i18n.tc('averageCheck.filterDateBy.createdAt'),
  },
  {
    id: 'processing',
    name: i18n.tc('averageCheck.filterDateBy.processingDate'),
  },
  {
    id: 'approve',
    name: i18n.tc('averageCheck.filterDateBy.approveDate'),
  },
  {
    id: 'shipped',
    name: i18n.tc('averageCheck.filterDateBy.shippedDate'),
  },
  {
    id: 'delivered',
    name: i18n.tc('averageCheck.filterDateBy.ransomDate'),
  },
]

export const itemsFilterDostavim = [
  {
    id: DOSTAVIM.DAY,
    name: i18n.tc('dostavim.filters.day'),
  },
  {
    id: DOSTAVIM.MONTH,
    name: i18n.tc('dostavim.filters.month'),
  },
  {
    id: DOSTAVIM.OFFER,
    name: i18n.tc('dostavim.filters.offer'),
  },
  {
    id: DOSTAVIM.OFFER_GROUP,
    name: i18n.tc('dostavim.filters.offer_group'),
  },
  {
    id: DOSTAVIM.OFFER_NICHE,
    name: i18n.tc('dostavim.filters.offer_niche'),
  },
  {
    id: DOSTAVIM.OPERATOR_GROUPS,
    name: i18n.tc('dostavim.filters.operator_groups'),
  },
  {
    id: DOSTAVIM.OPERATOR,
    name: i18n.tc('dostavim.filters.operator'),
  },
  {
    id: DOSTAVIM.COUNTRY,
    name: i18n.tc('dostavim.filters.country'),
  },
  {
    id: DOSTAVIM.REGION,
    name: i18n.tc('dostavim.filters.region'),
  },
  {
    id: DOSTAVIM.SALES_MODEL,
    name: i18n.tc('dostavim.filters.sales_model'),
  },
  {
    id: DOSTAVIM.SALES_TYPE,
    name: i18n.tc('dostavim.filters.sales_type'),
  },
]

export const itemsSelectPenalty = [
  {
    uuid: PENALTY.PENALTY,
    name: i18n.tc('penalty.penalty'),
  },
  {
    uuid: PENALTY.HOLD,
    name: i18n.tc('penalty.holds'),
  },
  {
    uuid: PENALTY.PROFIT,
    name: i18n.tc('penalty.accrual'),
  },
]

export const showFullPhoneNumberItems = [
  { uuid: 'full', name: i18n.tc('addSecurityPassword.showViewPhoneFull') },
  { uuid: 'partially', name: i18n.tc('addSecurityPassword.showViewPhonePartially') },
]

export const logoutUnavailableAfterItems = [
  { id: 15, name: i18n.tc('addSecurityPassword.more15min') },
  { id: 30, name: i18n.tc('addSecurityPassword.more30min') },
  { id: 60, name: i18n.tc('addSecurityPassword.more1hour') },
  { id: 120, name: i18n.tc('addSecurityPassword.more2hour') },
  { id: 180, name: i18n.tc('addSecurityPassword.more3hour') },
  { id: 300, name: i18n.tc('addSecurityPassword.more5hour') },
  { id: 1440, name: i18n.tc('addSecurityPassword.more24hour') },
  { id: null, name: i18n.tc('addSecurityPassword.noLogout') },
]

export const phoneViewLimitItems = [
  { id: 5, name: 5 },
  { id: 10, name: 10 },
  { id: 20, name: 20 },
  { id: 30, name: 30 },
  { id: 40, name: 40 },
  { id: 50, name: 50 },
  { id: null, name: i18n.tc('addSecurityPassword.noLimits') },
]

export const itemsSelectDeliveryServiceCode = [
  {
    uuid: 'RussianPost',
    name: i18n.tc('deliveryServiceCode.RussianPost'),
  },
  {
    uuid: 'TopDelivery',
    name: i18n.tc('deliveryServiceCode.TopDelivery'),
  },
  {
    uuid: 'Cdek',
    name: i18n.tc('deliveryServiceCode.Cdek'),
  },
  {
    uuid: 'Boxberry',
    name: i18n.tc('deliveryServiceCode.Boxberry'),
  },
  {
    uuid: 'Drhl',
    name: i18n.tc('deliveryServiceCode.Drhl'),
  },
  {
    uuid: 'Dpd',
    name: i18n.tc('deliveryServiceCode.Dpd'),
  },
  {
    uuid: 'Cse',
    name: i18n.tc('deliveryServiceCode.Cse'),
  },
  {
    uuid: 'FivePost',
    name: i18n.tc('deliveryServiceCode.FivePost'),
  },
  {
    uuid: 'PickPoint',
    name: i18n.tc('deliveryServiceCode.PickPoint'),
  },
  {
    uuid: 'YandexGo',
    name: i18n.tc('deliveryServiceCode.YandexGo'),
  },
  {
    uuid: 'YandexDelivery',
    name: i18n.tc('deliveryServiceCode.YandexDelivery'),
  },
  {
    uuid: 'Svyaznoy',
    name: i18n.tc('deliveryServiceCode.Svyaznoy'),
  },
  {
    uuid: 'DostavkaClub',
    name: i18n.tc('deliveryServiceCode.DostavkaClub'),
  },
  {
    uuid: 'DostavkaGuru',
    name: i18n.tc('deliveryServiceCode.DostavkaGuru'),
  },
]

export const itemsLogWatchNumber = [
  {
    uuid: 'watch_number',
    name: i18n.tc('logWatchNumber.watch_number'),
    event: 'watch_number',
  },
  {
    uuid: 'open_order',
    name: i18n.tc('logWatchNumber.open_order'),
    event: 'open_order',
  },
]

export const itemsLogAccounts = [
  {
    uuid: 'user_activation',
    name: i18n.tc('logAccounts.event.user_activation'),
    event: 'user_activation',
  },
  {
    uuid: 'user_deactivation',
    name: i18n.tc('logAccounts.event.user_deactivation'),
    event: 'user_deactivation',
  },
  {
    uuid: 'create',
    name: i18n.tc('logAccounts.event.create'),
    event: 'create',
  },
  {
    uuid: 'update',
    name: i18n.tc('logAccounts.event.update'),
    event: 'update',
  },
  {
    uuid: 'user_update_access',
    name: i18n.tc('logAccounts.event.user_update_access'),
    event: 'user_update_access',
  },
  {
    uuid: 'user_update_password',
    name: i18n.tc('logAccounts.event.user_update_password'),
    event: 'user_update_password',
  },
]

export const itemsLogAccountsTypeAccess = [
  {
    uuid: null,
    name: i18n.tc('base.all'),
  },
  {
    uuid: 'number_phone',
    name: i18n.tc('logAccounts.numberPhone'),
  },
  {
    uuid: 'edit_access',
    name: i18n.tc('logAccounts.editAccess'),
  },
]

export const itemsTypeUser = [
  {
    uuid: 'operator',
    name: i18n.tc('abNormal.operator'),
  },
  {
    uuid: 'manager',
    name: i18n.tc('abNormal.header'),
  },
]

export const itemsTypeAction = [
  {
    uuid: 'order',
    name: i18n.tc('abNormal.order'),
  },
  {
    uuid: 'phone',
    name: i18n.tc('abNormal.phone'),
  },
]

export const itemsNorms = [
  {
    uuid: 1,
    name: '1',
  },
  {
    uuid: 2,
    name: '2',
  },
  {
    uuid: 3,
    name: '3',
  },
  {
    uuid: 4,
    name: '4',
  },
  {
    uuid: 5,
    name: '5',
  },
  {
    uuid: 6,
    name: '6',
  },
  {
    uuid: 7,
    name: '7',
  },
  {
    uuid: 8,
    name: '8',
  },
  {
    uuid: 9,
    name: '9',
  },
  {
    uuid: 10,
    name: '10',
  },
  {
    uuid: 11,
    name: '11',
  },
  {
    uuid: 12,
    name: '12',
  },
  {
    uuid: 13,
    name: '13',
  },
  {
    uuid: 14,
    name: '14',
  },
  {
    uuid: 15,
    name: '15',
  },
  {
    uuid: 16,
    name: '16',
  },
  {
    uuid: 17,
    name: '17',
  },
  {
    uuid: 18,
    name: '18',
  },
  {
    uuid: 19,
    name: '19',
  },
  {
    uuid: 20,
    name: '20',
  },
  {
    uuid: 21,
    name: '21',
  },
  {
    uuid: 22,
    name: '22',
  },
  {
    uuid: 23,
    name: '23',
  },
  {
    uuid: 24,
    name: '24',
  },
]

export const intervalsUserSchedule = [
  {
    id: '07:00',
    name: '07:00',
  },
  {
    id: '08:00',
    name: '08:00',
  },
  {
    id: '09:00',
    name: '09:00',
  },
  {
    id: '10:00',
    name: '10:00',
  },
  {
    id: '11:00',
    name: '11:00',
  },
  {
    id: '12:00',
    name: '12:00',
  },
  {
    id: '13:00',
    name: '13:00',
  },
  {
    id: '14:00',
    name: '14:00',
  },
  {
    id: '15:00',
    name: '15:00',
  },
  {
    id: '16:00',
    name: '16:00',
  },
  {
    id: '17:00',
    name: '17:00',
  },
  {
    id: '18:00',
    name: '18:00',
  },
  {
    id: '19:00',
    name: '19:00',
  },
  {
    id: '20:00',
    name: '20:00',
  },
  {
    id: '21:00',
    name: '21:00',
  },
  {
    id: '22:00',
    name: '22:00',
  },
  {
    id: '23:00',
    name: '23:00',
  },
  {
    id: '00:00',
    name: '00:00',
  },
  {
    id: '01:00',
    name: '01:00',
  },
  {
    id: '02:00',
    name: '02:00',
  },
  {
    id: '03:00',
    name: '03:00',
  },
  {
    id: '04:00',
    name: '04:00',
  },
  {
    id: '05:00',
    name: '05:00',
  },
  {
    id: '06:00',
    name: '06:00',
  },
]

export const itemsSmsChainRating = [
  {
    uuid: 'good',
    name: i18n.tc('smsChain.rating.good'),
  },
  {
    uuid: 'neutral',
    name: i18n.tc('smsChain.rating.neutral'),
  },
  {
    uuid: 'bad',
    name: i18n.tc('smsChain.rating.bad'),
  },
  {
    uuid: 'invalid',
    name: i18n.tc('smsChain.rating.invalid'),
  },
]

export const itemsSmsChainStatuses = [
  {
    uuid: 'draft',
    name: i18n.tc('smsChain.statuses.draft'),
  },
  {
    uuid: 'error',
    name: i18n.tc('smsChain.statuses.error'),
  },
  {
    uuid: 'pending',
    name: i18n.tc('smsChain.statuses.pending'),
  },
  {
    uuid: 'created',
    name: i18n.tc('smsChain.statuses.created'),
  },
  {
    uuid: 'wait-delivery',
    name: i18n.tc('smsChain.statuses.wait-delivery'),
  },
  {
    uuid: 'intransit',
    name: i18n.tc('smsChain.statuses.intransit'),
  },
  {
    uuid: 'dispatched-to-a-courier',
    name: i18n.tc('smsChain.statuses.dispatched-to-a-courier'),
  },
  {
    uuid: 'stored',
    name: i18n.tc('smsChain.statuses.stored'),
  },
  {
    uuid: 'delivered',
    name: i18n.tc('smsChain.statuses.delivered'),
  },
  {
    uuid: 'expected-return',
    name: i18n.tc('smsChain.statuses.expected-return'),
  },
  {
    uuid: 'return-arrived-warehouse',
    name: i18n.tc('smsChain.statuses.return-arrived-warehouse'),
  },
  {
    uuid: 'return-completed',
    name: i18n.tc('smsChain.statuses.return-completed'),
  },
  {
    uuid: 'losted',
    name: i18n.tc('smsChain.statuses.losted'),
  },
  {
    uuid: 'unknown',
    name: i18n.tc('smsChain.statuses.unknown'),
  },
  {
    uuid: 'cancelled',
    name: i18n.tc('smsChain.statuses.cancelled'),
  },
]

export const itemsMessageChainUnitType = [
  {
    uuid: 'sms',
    name: i18n.tc('smsChain.type.sms'),
  },
  {
    uuid: 'operator_call',
    name: i18n.tc('smsChain.type.operatorCall'),
  },
]

export const itemsTimeAfterUnit = [
  {
    uuid: 'day',
    name: i18n.tc('smsChain.timeAfterObj.day'),
  },
  {
    uuid: 'hour',
    name: i18n.tc('smsChain.timeAfterObj.hour'),
  },
  {
    uuid: 'minute',
    name: i18n.tc('smsChain.timeAfterObj.minute'),
  },
]

export const activeSelectSmsChain = [
  {
    uuid: true,
    name: i18n.tc('smsChain.activeStatusChain'),
  },
  {
    uuid: false,
    name: i18n.tc('smsChain.deactiveStatusChain'),
  },
]

export const itemsFiltersOrderAttribute = [
  {
    uuid: 'cold',
    name: i18n.tc('order.attributes.cold'),
  },
  {
    uuid: 'hot',
    name: i18n.tc('order.attributes.hot'),
  },
  {
    uuid: 'no_ds',
    name: i18n.tc('order.attributes.no_ds'),
  },
  {
    uuid: 'trash_geo',
    name: i18n.tc('order.attributes.trash_geo'),
  },
  {
    uuid: 'approve_after_cancel',
    name: i18n.tc('order.attributes.approve_after_cancel'),
  },
  {
    uuid: 'cancel',
    name: i18n.tc('order.attributes.cancel'),
  },
  {
    uuid: 'no_manager',
    name: i18n.tc('order.attributes.no_manager'),
  },
  {
    uuid: 'call_queue_error',
    name: i18n.tc('order.attributes.call_queue_error'),
  },
]
