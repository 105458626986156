export const RECONFIRMATION_STATISTICS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  OPERATOR: 'operator',
  OPERATOR_GROUPS: 'operator_groups',
  DELIVERY_SERVICE: 'delivery_service',
  DELIVERY_TYPE: 'delivery_type',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
  AGE: 'age',
  GENDER: 'gender',
}
