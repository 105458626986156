import moment from 'moment/moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    createdAt: {
      value: `${moment()
        .startOf('month')
        .format(DATE_TIME_FORM_FULL_FORMAT)}|${moment()
        .endOf('month')
        .format(DATE_TIME_FORM_FULL_FORMAT)}`,
      operator: 'bwn',
    },
    warehouseFrom: {
      value: '',
      operator: 'in'
    },
    warehouseTo: {
      value: '',
      operator: 'in'
    },
    product: {
      value: '',
      operator: 'in'
    },
    createdBy: {
      value: '',
      operator: 'in'
    },
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
