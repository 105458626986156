import { orderStatusesCallCentre } from '@/helpers/select-items'

export default [
  {
    path: '/call-centre/order',
    name: 'pageOrderList',
    meta: { title: 'page.pageOrderList', roles: ['CC_SHOW_SECTION'] },
    component: () => import('@/pages/call-centre/order'),
  },
  {
    path: '/call-centre/order/create',
    name: 'pageOrderCreate',
    meta: { title: 'page.pageOrderCreate', roles: ['CC_ORDER_CREATE'] },
    component: () => import('@/pages/call-centre/order/create'),
  },
  {
    path: '/call-centre/order/:id/edit',
    name: 'pageOrderEdit',
    meta: {
      title: 'page.pageOrderEdit',
      roles: orderStatusesCallCentre.map(s => `CC_ORDER_VIEW_${s.id.toUpperCase()}`)
    },
    component: () => import('@/pages/call-centre/order/id/edit'),
  },
]
