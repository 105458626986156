export const APPROVE_SPEED = {
  HOURS: 'hours',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  COUNTRY: 'country',
  REGION: 'region',
  USER_GROUP: 'user_group',
  OPERATOR: 'operator',
  LANDING: 'landing',
  WEB: 'web',
  DELIVERY_SERVICE: 'delivery_service',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
  AGE: 'age',
  GENDER: 'gender',
}
