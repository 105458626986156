export const CALL_STATISTICS = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  COUNTRY: 'country',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  OPERATOR: 'operator',
  OPERATOR_GROUPS: 'operator_groups',
  DELIVERY_SERVICE: 'delivery_service',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
  AGE: 'age',
  GENDER: 'gender',
  WEB: 'web',
}
