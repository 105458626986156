import i18n from "@/plugins/vue-i18n";

export const CALL_STATUS = {
  DIALING: 'dialing',
  CALL: 'call',
  SUCCESS: 'success',
  FAILED_ATTEMPT: 'failed_attempt',
  FAILED: 'failed',
}

export const callStatTranslation = {
  [CALL_STATUS.DIALING]: i18n.tc('callStatuses.dialing'),
  [CALL_STATUS.CALL]: i18n.tc('callStatuses.call'),
  [CALL_STATUS.SUCCESS]: i18n.tc('callStatuses.success'),
  [CALL_STATUS.FAILED_ATTEMPT]: i18n.tc('callStatuses.failed_attempt'),
  [CALL_STATUS.FAILED]: i18n.tc('callStatuses.failed'),
}