export const DOSTAVIM = {
  DAY: 'day',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  OPERATOR_GROUPS: 'operator_groups',
  OPERATOR: 'operator',
  COUNTRY: 'country',
  REGION: 'region',
  SALES_MODEL: 'sales_model',
  SALES_TYPE: 'sales_type',
}
