import i18n from '@/plugins/vue-i18n'

export const KLN_STATUS = {
  FINISHED: 'finished',
  PENDING: 'pending',
  CREATED: 'created',
}

export const KLN_COLOR = {
  [KLN_STATUS.FINISHED]: 'success',
  [KLN_STATUS.PENDING]: 'warning',
  [KLN_STATUS.CREATED]: 'black darken-1',
}

export const KLN_TRANSACTION = {
  [KLN_STATUS.FINISHED]: i18n.tc('klnAssessment.workStatus.finished'),
  [KLN_STATUS.PENDING]: i18n.tc('klnAssessment.workStatus.pending'),
  [KLN_STATUS.CREATED]: i18n.tc('klnAssessment.workStatus.created'),
}
