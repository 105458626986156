export const AVERAGE_CHECK = {
  WEEK: 'week',
  MONTH: 'month',
  OFFER: 'offer',
  OFFER_GROUP: 'offer_group',
  OFFER_NICHE: 'offer_niche',
  COUNTRY: 'country',
  REGION: 'region',
  CITY: 'city',
  OPERATOR_GROUPS: 'operator_groups',
  OPERATOR: 'operator',
  LANDING: 'landing',
  WEB: 'web',
  REJECT_REASON: 'reject_reason',
  DELIVERY_SERVICE: 'delivery_service',
  SALES_MODEL: 'sales_model',
  PRODUCT_TYPE: 'product_type',
  AGE: 'age',
  GENDER: 'gender',
  WAREHOUSE: 'warehouse',
}
