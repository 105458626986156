import { orderStatusesCallCentre } from '@/helpers/select-items'

export default [
  {
    path: '/notice-department/orders',
    name: 'pageNoticeDepartmentOrders',
    meta: {
      title: 'page.pageNoticeDepartment',
      roles: orderStatusesCallCentre.map(s => `SIDEBAR_ORDER_VIEW_${s.id.toUpperCase()}`)
    },
    component: () => import('@/pages/notice-department/orders'),
  },
  {
    path: '/notice-department/orders/create',
    name: 'pageNoticeDepartmentCreate',
    meta: {
      title: 'page.pageOrderCreate',
      roles: ['SIDEBAR_ORDER_OPERATE', 'SIDEBAR_ORDER_CREATE'],
    },
    component: () => import('@/pages/notice-department/orders/create'),
  },
  {
    path: '/notice-department/orders/:id/edit',
    name: 'pageNoticeDepartmentEdit',
    meta: {
      title: 'page.pageOrderEdit',
      roles: orderStatusesCallCentre.map(s => `SIDEBAR_ORDER_VIEW_${s.id.toUpperCase()}`)
    },
    component: () => import('@/pages/notice-department/orders/id/edit'),
  },
]
